#documentsDiv {
  padding-top: 0;

  .pure-table {
    .snippet-container {
      margin-top: 10px;
    }
  }
}

%docs-col-no-border {
  border: 0 !important;
}

.docsFirstCol {
  @extend %docs-col-no-border;
  height: 26px;
  width: 80%;
}

.docsSecCol {
  @extend %docs-col-no-border;
  height: 26px;
  min-width: 400px !important;
  width: 10%;
}

.docsThirdCol {
  @extend %docs-col-no-border;
  @extend %pull-right;

  height: 26px;
}

.removeFilterItem,
.add-filter-item {
  margin-left: 8px;

  i {
    margin-top: 5px !important;
  }
}

.upload-indicator {
  display: none;
  margin-top: -3px;
  padding-right: 10px;
}

.documentsDropdown {
  .dropdownImport,
  .headerDropdown {
    clear: both;
    margin-bottom: 10px;
  }
}

.documents-size {
  background-color: $c-white !important;
  border: 1px solid $c-grey;
  border-radius: 0;
  box-shadow: none;
  color: $c-black;
  float: right;
  font-size: 11pt;
  font-weight: 300;
  height: 28px;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 0;
  outline: none;
  outline-color: transparent;
  outline-style: none;
  width: 115px;
}

.queryline {
  height: 37px !important;

  .fa-info-circle:hover {
    cursor: pointer;
  }

  input,
  select {
    float: left;
  }

  select {
    height: 32px;
  }
}

.headerDropdown .queryline:last-child {
  margin-bottom: -2px;
}

.ace_error {
  background: none !important;
}

#exportHeader .fa-exclamation-circle {
  color: $c-nav-bg;
  font-size: 13pt;
  margin-right: 10px;
}

.totalDocuments:hover {
  color: $c-black;
}

#recalculateButton {
  margin-left: 10px;
  background-color: var(--gray-200);
  cursor: pointer;
}
