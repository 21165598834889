:root {
  --green-50: #f5faeb;
  --green-100: #e9f4d3;
  --green-200: #d5e9ad;
  --green-300: #b8da7c;
  --green-400: #9dc853;
  --green-500: #7ead35;
  --green-600: #608726;
  --green-700: #4b6922;
  --green-800: #3e5420;
  --green-900: #35481f;
  --green-950: #1a270c;
  --red-50: #fff2f1;
  --red-100: #ffe1df;
  --red-200: #ffc7c4;
  --red-300: #ffa19b;
  --red-400: #ff6a61;
  --red-500: #ff3b30;
  --red-600: #e6190d;
  --red-700: #cc1409;
  --red-800: #a8150c;
  --red-900: #8b1811;
  --red-950: #4c0703;
  --gray-50: #f8f8f8;
  --gray-100: #f0f0f0;
  --gray-200: #e5e5e5;
  --gray-300: #d1d1d1;
  --gray-400: #b4b4b4;
  --gray-500: #9a9a9a;
  --gray-600: #818181;
  --gray-700: #6a6a6a;
  --gray-800: #5a5a5a;
  --gray-900: #4e4e4e;
  --gray-950: #282828;
  --yellow-50: #fef9e8;
  --yellow-100: #fef0c3;
  --yellow-200: #fee28a;
  --yellow-300: #fdd147;
  --yellow-400: #fac215;
  --yellow-500: #eab308;
  --yellow-600: #ca9a04;
  --yellow-700: #a17c07;
  --yellow-800: #85680e;
  --yellow-900: #715a12;
  --yellow-950: #423306;

  --blue-50: #f0faff;
  --blue-100: #e0f4fe;
  --blue-200: #bae6fd;
  --blue-300: #7dd1fc;
  --blue-400: #38b7f8;
  --blue-500: #0e9fe9;
  --blue-600: #0284c7;
  --blue-700: #036ba1;
  --blue-800: #075a85;
  --blue-900: #0c4d6e;
  --blue-950: #083349;

  --orange-50: #fff4ed;
  --orange-100: #ffe6d5;
  --orange-200: #feccaa;
  --orange-300: #fdac74;
  --orange-400: #fb8a3c;
  --orange-500: #f97316;
  --orange-600: #ea670c;
  --orange-700: #c2570c;
  --orange-800: #9a4a12;
  --orange-900: #7c3d12;
  --orange-950: #432007;

  --button-text-color: white;
  --font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --mono-font-family: droid sans mono, monospace, courier new, courier, sans-serif;
}

$c-body-color: black;
$c-login-bg: #dee8d0;

$c-lightgreen-bg: rgba(222, 233, 207, 1);
$c-lightgreen-2-bg: #f5f8f0;
$c-lightgreen-transp-bg: #f5f8f0;
$c-lightgreen-2-transp-bg: rgba(245, 248, 240, .95);

$c-pong-green: rgb(140, 219, 139);

$c-positive: var(--green-600);
$c-positive-hover: var(--green-700);

$c-positive-high-transparency: rgba($c-positive, 0.1);
$c-positive-low-transparency: rgba($c-positive, 0.2);

$c-table-odd-color: rgba(104, 103, 102, .05);

$c-negative: var(--red-600);
$c-negative-hover: var(--red-700);
$c-negative-bg: desaturate(lighten(#cc1409, 40%), 50%);

$c-neutral: #8f8d8c;
$c-neutral-hover: #736b68;

$c-warning: var(--yellow-500);
$c-warning-hover: var(--yellow-600);
$c-unloaded: #ff8f35;

$c-primary: #34495e;
$c-primary-hover: #415b76;

$c-notification: var(--orange-600);
$c-notification-hover: var(--orange-700);

$c-inactive: #d3d3d3;
$c-inactive-hover: #d3d3d3;

$c-header-btn-bg: #fff;
$c-header-btn-fg: #555;
$c-header-btn-border: #fff;

$c-black: #000;
$c-white: #fff;
$c-white-transp: rgba(255, 255, 255, .85);
$c-yellow: #ff0;

$c-c2grey: #c2c2c2;

$c-nav-bg: $c-white;
$c-bar-bg: #686766;
$c-bar-bg-primary: rgba(0, 0, 0, .7);

$c-grey: #e5e5e5;
$c-e1grey: #e1e1e1;
$c-dark-grey: #999;
$c-darker-grey: #666;

$c-new-grey: #8c8a89;
$c-content-border: rgba(140, 138, 137, .25);
$c-tile-border: rgba(140, 138, 137, .25);

$c-transp: transparent;
$c-low-transp: rgba(0, 0, 0, .7);
$c-halfsemi-transp: rgba(0, 0, 0, .3);
$c-semi-transp: rgba(0, 0, 0, .2);
$c-very-transp: rgba(0, 0, 0, .05);
$c-content-transp: rgba(0, 0, 0, .0675);
$c-scenario-border-transp: rgba(0, 0, 0, .19);
$c-scenario-bg-transp: rgba(0, 0, 0, .075);
$c-arangodb-locked: rgba(0, 0, 0, .5);

$c-content-row-active: #bdcc92;

$c-odd: #d9d9d9;
$c-even: #fff;

$c-navbar-main-color: rgba(34, 34, 34, .9);
$c-navbar-spacer: #a0a0a0;
$c-navbar-border: rgba(140, 138, 137, .25);
$c-navbar-spacer-shadow: #c8c8c8;

$c-btn-inverse: #736b68;

$c-shell-error: #f06;
$c-shell-success: #6f0;
$c-shell-bg: #808080;
$c-shell-losing-focus: #c4cccc;

$c-shell-prompt: #b91;
$c-shell-old-prompt: #f60;

$c-slider-bg: #f6f6f6;
$c-shell-input: #dd0;
$c-shell-old-input: #bb0;

$c-notification-red: $c-negative;
$c-invalid-red: rgba(234, 23, 23, .6);

$c-info-blue: #5bc0de;
$c-blue: #00f;

//$c-info: #5bc0de;
//$c-info-hover: #5bc0de;
$c-info: #3498db;
$c-info-hover: #5dade2;

$c-cluster-button-green: #617e2b;
$c-cluster-button-green-hover: #8ba142;

$c-cluster-button-grey: #f1f1f1;
$c-cluster-button-grey-fg: #333;
$c-cluster-button-grey-border: rgba(0, 0, 0, .1875);
$c-cluster-button-grey-hover: #e8e8e8;
$c-cluster-button-grey-hover-fg: #4a6c30;

$c-chart-border: #808080;

$c-state-ok: #aa0;
$c-state-warning: #070;
$c-state-critical: #700;
$c-icon-grey: #686766;

$c-toolbar-bg: #f0f0f0;

$c-editor-toolbar: #a0a0a0;

$c-query-output-border: #c0c0c0;

$c-tab-inactive: #f1f0ee;
$c-tab-active: #fff;
$c-tab-bottom-border: #ddd;
$c-tab-border: #888;

$c-pag-disabled-fg: #666;
$c-pag-disabled-bg: #777;

$c-accordion-heading: #ccc;

$c-icon-with-opacity: #333;

$c-api-docs-bg: #f4f3f3;
$c-api-docs-bg-input: #8f8d8c;

$c-breadcrumb-bg: #f5f5f5;
$c-breadcrumb-a-active-bread: #7da817;
$c-breadcrumb-a-active-bread-transp: rgba(185, 211, 117, .2);

$c-logs-table-id-thead-bg: #f9f9f9;

$c-viewer-border: rgba(0, 0, 0, .125);

$c-modal-header: #fff;
$c-modal-table-border-bottom: #f7f3f2;
$c-collection-tab-border-bottom: #888;

$c-sh-number: #044;
$c-sh-symbol: #00f;
// $c-sh-cbracket: #c7a317;
$c-sh-cbracket: rgb(64, 74, 83);
$c-sh-keyword: #c12dad;
$c-sh-string: #ce2f30;
$c-sh-object: #00f;
$c-sh-array: #00f;

$c-spotlight-bg: rgb(61, 66, 70);
$c-spotlight-header-bg: rgb(50, 55, 59);
$c-spotlight-transp-bg: rgba(0, 0, 0, .25);

$c-progress-bar: #5bc0de;
$c-progress-shadow: #353c39;
$c-progress-shadow-2: #8cdb8b;
$c-progress-bg: #363c39;

$c-pure-title: rgb(113, 125, 144);
$c-pure-text: rgb(138, 150, 159);

$c-bluegrey-dark: rgba(64, 74, 83, 1);
$c-bluegrey-light: rgba(64, 74, 83, .8);
$c-bluegrey-border: rgba(64, 74, 83, .2);
$c-bluegrey-nontransp: rgb(217, 219, 220);
$c-bluegrey-bg: rgba(64, 74, 83, .04);

$c-active-green: rgb(119, 203, 153);
$c-test-green: rgb(100, 144, 104);
$c-test-green-transp: rgba(100, 144, 104, .7);
$c-login-grey: #f2f2f2;

